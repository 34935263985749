@import 'colors';
@import 'typography';

@import 'buttons';
@import 'cards';
@import 'dialog';
@import 'forms';
@import 'navbar';
@import 'snackbar';
@import 'sidebar';

html, body {
  margin: 0;
  scrollbar-color: #ccc #fff;
  scrollbar-width: thin;
  color: map-get($colors, 'grey-800');
}

body * {
  box-sizing: border-box;
}

// [data-overlayscrollbars~='host'],
// [data-overlayscrollbars~='viewport'] {
//   height: 100vh;
// }
