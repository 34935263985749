.theme-card {
  background-color: #fff;
  border: thin solid map-get($colors, 'grey-50');
  border-radius: 16px;
}

.theme-card-heading {
  padding: 16px 24px;
  border-bottom: thin solid map-get($colors, 'grey-50');

  span {
    font-size: 16px;
    font-weight: 700;
    color: map-get($colors, 'grey-500');
  }
}

.theme-card-body {
  padding: 24px;
}

.theme-card-variant-no-border {
  border: none;
}
