.theme-form-input-container {
  position: relative;
}

.theme-form-input-label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #333;

  &.theme-form-input-label-bold {
    font-size: 16px;
    font-weight: 700;
  }

  span {
    color: map-get($colors, 'red-500');
  }
}

.theme-form-sublabel {
  font-size: 14px;
  margin-bottom: 12px;
  display: block;
  color: map-get($colors, 'grey-300');
}

.theme-form-helpblock {
  font-size: 14px;
  margin-top: 12px;
  display: block;
  color: map-get($colors, 'grey-300');
}

.theme-form-control {
  font-family: "SF Pro Text";
  padding: 20px 12px;
  border: thin solid map-get($colors, 'grey-200');
  color: map-get($colors, 'grey-800');
  border-radius: 8px;
  height: 56px;
  font-weight: 500;
  width: 100%;

  &:active,
  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    border-color: map-get($colors, 'grey-500');
  }

  &::placeholder {
    font-weight: 400;
    color: map-get($colors, 'grey-300');
  }

  &[disabled] {
    background-color: map-get($colors, 'grey-50');
  }
}

.theme-form-control-has-error {
  border-color: map-get($colors, 'red-500');

  &:active,
  &:hover,
  &:focus,
  &:focus-visible {
    border-color: map-get($colors, 'red-500');
  }
}

.theme-form-icon-adornment {
  position: absolute;
  right: 10px;
  top: 50%;

  &:hover {
    cursor: pointer;
  }

  img,
  svg {
    width: 24px;
    height: 24px;
  }
}

.theme-form-error-message {
  margin-top: 4px;
}

.theme-form-input-icon {
  position: absolute;
  right: 12px;
  top: 16px;
}
