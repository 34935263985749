.theme-btn {
  font-family: 'SF Pro Display', sans-serif;
  padding: 10px 12px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #fff;
  transition: background-color 0.2s ease;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;

    & ~ div {
      height: 24px;
    }
  }

  @each $color in $colorThemes {
    &.theme-btn-color-#{$color} {
      background-color: map-get($colors, '#{$color}-500');

      &[disabled],
      &:hover {
        background-color: map-get($colors, '#{$color}-200');
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.theme-btn-outline {
    background-color: transparent;
    transition:
    border-color 0.2s ease,
    color 0.2s ease;

    @each $color in $colorThemes {
      &.theme-btn-color-#{$color} {
        border: thin solid map-get($colors, '#{$color}-500');
        color: map-get($colors, '#{$color}-500');

        &[disabled],
        &:hover {
          background-color: transparent;
          border-color: map-get($colors, '#{$color}-200');
          color: map-get($colors, '#{$color}-200');
        }
      }
    }
  }

  &.theme-btn-no-border {
    border: none;
    background-color: transparent;

    @each $color in $colorThemes {
      &.theme-btn-color-#{$color} {
        color: map-get($colors, '#{$color}-500');

        &[disabled],
        &:hover {
          background-color: transparent;
          color: map-get($colors, '#{$color}-200');
        }
      }
    }
  }
}

.theme-btn-fullwidth {
  width: 100%;
}

.theme-btn-size-sm {
  padding: 8px;
  font-size: 14px;
  line-height: 140%;
}

.theme-btn-size-md {
  padding: 10px 12px;
  font-size: 16px;
}

.theme-btn-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  svg {
    width: 24px;
    height: 24px;
    margin-top: 6px;
  }
}
