.theme-snackbar {
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  width: 480px;
}

.theme-snackbar-icon {
  width: 24px;
  height: 24px;
}

.theme-snackbar-close-button {
  width: 22px;
  height: 22px;
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

.theme-snackbar-success {
  background: map-get($colors, 'green-500');
  color: #fff;
}

.theme-snackbar-warning {
  background: map-get($colors, 'yellow-500');
  color: #fff;
}

.theme-snackbar-error {
  background: map-get($colors, 'red-500');
  color: #fff;
}
