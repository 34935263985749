.theme-dialog-wrapper {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100%;
  height: calc(100% - 1rem);

  @media (min-width: 768px) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.theme-dialog-backdrop {
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.theme-dialog-close {
  position: absolute;
  right: 24px;
  top: 16px;

  &:hover {
    cursor: pointer;
  }
}

.theme-dialog {
  border-radius: 16px;
  z-index: 101;
  background-color: #fff;
  position: relative;

  &.theme-dialog-sm {
    width: 558px;
  }

  &.theme-dialog-md {
    width: 752px;
  }

  &.theme-dialog-lg {
    width: 898px;
  }

  .theme-dialog-header {
    padding: 16px 24px;

    h4 {
      font-family: 'SF Pro Display';
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }

  .theme-dialog-body {
    padding: 16px 24px;
    max-height: 450px;
    overflow-y: auto;
    scrollbar-color: #ccc #fff;
    scrollbar-width: thin;
  }

  .theme-dialog-footer {
    padding: 24px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 75px;
      height: 48px;
      pointer-events: all;
    }
  }
}
