@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

@font-face {
  font-family: 'SF Pro Text';
  src:
    url('./fonts/sf-pro-text-bold-webfont.eot') format('eot'),
    url('./fonts/sf-pro-text-bold-webfont.ttf') format('ttf'),
    url('./fonts/sf-pro-text-bold-webfont.woff2') format('woff2'),
    url('./fonts/sf-pro-text-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src:
    url('./fonts/sf-pro-text-medium-webfont.eot') format('eot'),
    url('./fonts/sf-pro-text-medium-webfont.ttf') format('ttf'),
    url('./fonts/sf-pro-text-medium-webfont.woff2') format('woff2'),
    url('./fonts/sf-pro-text-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src:
    url('./fonts/sf-pro-text-regular-webfont.eot') format('eot'),
    url('./fonts/sf-pro-text-regular-webfont.ttf') format('ttf'),
    url('./fonts/sf-pro-text-regular-webfont.woff2') format('woff2'),
    url('./fonts/sf-pro-text-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src:
    url('./fonts/sf-pro-text-semibold-webfont.eot') format('eot'),
    url('./fonts/sf-pro-text-semibold-webfont.ttf') format('ttf'),
    url('./fonts/sf-pro-text-semibold-webfont.woff2') format('woff2'),
    url('./fonts/sf-pro-text-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'SF Pro Text';
  font-size: 14px;
  line-height: 140%;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  font-style: normal;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.theme-font-sf-pro {
  font-family: 'SF Pro Text', sans-serif;
}

.theme-font-sf-pro-display {
  font-family: 'SF Pro Display', sans-serif;
}

.theme-font-h1,
.theme-font-h2,
.theme-font-h3,
.theme-font-h4,
.theme-font-h5,
.theme-font-h6 {
  font-family: 'SF Pro Display', sans-serif;
}

.theme-font-h1 {
  font-size: 36px;
  font-weight: 700;
}

.theme-font-h2 {
  font-size: 32px;
  font-weight: 700;
}

.theme-font-h3 {
  font-size: 28px;
  font-weight: 700;
}

.theme-font-h4 {
  font-size: 24px;
  font-weight: 700;
}

.theme-font-h5 {
  font-size: 24px;
  font-weight: 600;
}

.theme-font-lg {
  font-size: 18px;
}

.theme-font-md {
  font-size: 16px;
}

.theme-font-sm {
  font-size: 12px;
}

.theme-font-xs {
  font-size: 10px;
}
