.theme-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 178px;
  height: 100vh;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  padding-top: 58px;
  border-right: 1px solid map-get($colors, 'grey-50');

  @media only screen and (max-width: 600px) {
    padding-top: 58px;
    width: 305px;
    transform: translate(-100%);
  }

  &.theme-sidebar-open {
    transform: none;
  }
}

.theme-sidebar-wrapper {
  height: 100%;
  background-color: #fff;
}

.theme-sidebar-logo-wrapper {
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  align-items: center;
  border-bottom: thin solid map-get($colors, 'grey-50');
}

.theme-sidebar-logo {
  width: 66px;
  height: 24px;
  object-fit: contain;
  object-position: center;
}

.theme-sidebar-title {
  font-size: 12px;
  font-weight: 700;
  padding: 14px 16px;
  display: block;
}

.theme-sidebar-menu {
  font-weight: 500;
  list-style-type: none;
  padding-left: 3px;
  padding-right: 3px;
  margin: 0;
  position: relative;
  z-index: 2;
  background-color: #fff;

  li {
    position: relative;

    button {
      font-family: "SF Pro Text";
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      border: none;
      background-color: transparent;
      padding: 14px 8px;
      border-radius: 100px;

      img {
        filter: grayscale(1);
      }

      &.theme-sidebar-item-active {
        background-color: map-get($colors, 'purple-50');
        color: map-get($colors, 'purple-500');

        img {
          filter: grayscale(0);
        }
      }
    }

    .theme-sidebar-icon {
      width: 24px;
      height: 24px;
    }

    .theme-sidebar-link {
      text-align: left;
      padding: 14px 8px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 500;
      color: map-get($colors, 'grey-500');

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.theme-sidebar-children {
  list-style-type: none;
  padding-left: 0;
  margin: 0;

  li {
    border-radius: 100px;
    padding: 0;
    margin-bottom: 4px;

    &:hover {
      cursor: pointer;
    }

    &.theme-sidebar-item-active {
      background-color: map-get($colors, 'purple-50');

      span {
        color: map-get($colors, 'purple-500');
      }
    }

    .theme-sidebar-child-item {
      padding: 14px 8px 14px 36px;
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        object-position: center;
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &.theme-sidebar-children-hidden {
    display: none;
  }
}

.theme-sidebar-item-wrapper {
  margin-left: -22px;
}

.theme-sidebar-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.theme-sidebar-arrow {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);

  &.is-opened {
    transform: rotate(180deg);
  }

  &:hover {
    cursor: pointer;
  }
}

.theme-sidebar-footer {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 12px 0;

  a {
    font-size: 8px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    line-height: 140%;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    border-top: thin solid map-get($colors, 'grey-50');
    padding: 8px;
  }
}
