.theme-navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px 24px;
  z-index: 50;
  background-color: map-get($colors, 'purple-400');

  @media only screen and (max-width: 600px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  .theme-navbar-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .theme-navbar-sidebar-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        display: inline-flex;;
        align-items: center;
        color: #fff;

        @media (min-width: 640px) {
          display: none;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.theme-navbar-buttons {
  display: flex;
  margin-left: 0.75rem;
  margin-left: 0.875rem;
  gap: 8px;
  align-items: center;

  @media (min-width: 768px) {
    margin-left: 0;
    order: 2;
  }

  button {
    transition: background-color 0.2s ease;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 8px;

    &:hover {
      background-color: map-get($colors, 'purple-300');
      cursor: pointer;
    }
  }
}

.theme-navbar-icons {
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
}

.theme-navbar-dropdown {
  display: none;
  margin-top: 8px;
  border-radius: 16px;
  border: thin solid map-get($colors, 'grey-50');
  background-color: #fff;
}

.theme-navbar-quicklinks-dropdown {
  padding: 16px;
  width: 526px;
  z-index: 50;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    li {
      border: thin solid map-get($colors, 'grey-50');
      width: calc(25% - 8px);
      background-color: #fff;
      transition: background-color 0.2s ease;

      &:hover {
        cursor: pointer;
        background-color: map-get($colors, 'grey-25');
      }

      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
        object-position: center;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      a {
        text-decoration: none;
        color: map-get($colors, 'purple-400');
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 10px;

        span {
          text-align: center;
        }
      }
    }
  }

  &.dropdown-visible {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(calc(100vw - 633px), 58px);
  }
}

.theme-navbar-notif-dropdown {
  width: 558px;
  max-height: 600px;
  overflow-y: auto;
  scrollbar-color: #ccc #fff;
  scrollbar-width: thin;
  z-index: 50;

  &.dropdown-visible {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(calc(100vw - 633px), 58px);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 20px;
      background-color: map-get($colors, 'purple-25');
      border-bottom: thin solid map-get($colors, 'grey-50');

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
      }

      .theme-navbar-notif-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.icon-default {
          background-color: map-get($colors, 'purple-25');
        }

        &.icon-user {
          background-color: map-get($colors, 'purple-200');
          color: #fff;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .theme-navbar-notif-content {
        p {
          color: map-get($colors, 'grey-800');
          margin-bottom: 4px;

          &:first-child {
            font-size: 14px;
            font-weight: 700;
          }

          &:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
          }
        }

        small {
          color: map-get($colors, 'grey-300');
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.theme-navbar-notif-has-read {
        background-color: #fff;

        p {
          &:first-child {
            font-weight: 500;
          }
        }
      }

      .theme-navbar-notif-readicon {
        margin-left: auto;
        width: 16px;
        height: 16px;
        background-color: map-get($colors, 'purple-500');
        border-radius: 50%;
      }

      .theme-navbar-notif-icon-stack {
        display: inline-flex;
        flex-direction: row-reverse;

        .theme-navbar-notif-icon {
          overflow: hidden;
          width: 28px;
          height: 28px;

          &:not(:last-child) {
            margin-left: -18px;
            margin-top: 10px;
          }

          &:first-child {
            background-color: map-get($colors, 'purple-300');
          }
        }
      }
    }
  }

  .theme-navbar-notif-empty-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 558px;

    svg {
      width: 100px;
      height: 100px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      margin-top: 4px;
      margin-bottom: 4px;
      color: map-get($colors, 'grey-300');

      &.title {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.theme-navbar-avatar {
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: map-get($colors, 'purple-200');
}

.theme-navbar-avatar-name {
  display: none;
  color: #ffffff;
  font-weight: 500;

  @media (min-width: 768px) {
    display: block;
  }
}

.theme-navbar-user-dropdown {
  width: 230px;
  z-index: 50;
  background-color: #fff;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 16px 24px;
      transition: background-color 0.2s ease;
      border-bottom: thin solid map-get($colors, 'grey-50');

      &:last-child {
        border-bottom: none;
      }

      a {
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        color: map-get($colors, 'grey-500');
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          width: 12px;
          height: 12px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: map-get($colors, 'grey-25');
      }
    }
  }

  &.dropdown-visible {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(calc(100vw - 258px), 58px);
  }
}
