$colorThemes: ('blue', 'red', 'purple', 'grey', 'green', 'yellow');

$colors: (
  "blue-50": #E1F4FA,
  "blue-100": #B5E2F3,
  "blue-200": #87CFEB,
  "blue-300": #60BCE2,
  "blue-400": #49AEDD,
  "blue-500": #3BA0D7,
  "blue-600": #3593C9,
  "blue-700": #2E80B6,
  "blue-800": #2A70A2,
  "blue-900": #20517F,
  "cool-grey-400": #A0AEC0,
  "green-50": #E7F5E8,
  "green-100": #C6E5C7,
  "green-200": #A2D5A3,
  "green-300": #7CC67E,
  "green-400": #5FB963,
  "green-500": #42AD47,
  "green-600": #399E3E,
  "green-700": #2D8C34,
  "green-800": #227B29,
  "green-900": #075213,
  "grey-10": #F9FAFB,
  "grey-25": #F4F5F7,
  "grey-50": #E7E9ED,
  "grey-100": #C0C9D5,
  "grey-200": #99A6B9,
  "grey-300": #74849C,
  "grey-400": #566A89,
  "grey-500": #395278,
  "grey-600": #334A70,
  "grey-700": #2B4165,
  "grey-800": #253858,
  "purple-25": #F3F3FB,
  "purple-50": #E8E9F9,
  "purple-100": #C5C8EF,
  "purple-200": #9EA5E5,
  "purple-300": #7581DA,
  "purple-400": #5665D2,
  "purple-500": #3548C9,
  "purple-600": #2536B2,
  "purple-700": #2536B2,
  "purple-800": #1B2BA7,
  "purple-900": #011694,
  "red-50": #FBEBF0,
  "red-100": #F7CED8,
  "red-200": #E59CA5,
  "red-300": #D97683,
  "red-400": #E55667,
  "red-500": #EB4553,
  "red-600": #DB3D50,
  "red-700": #C93549,
  "red-800": #BC2F41,
  "red-900": #921221,
  "yellow-50": #FFF3DF,
  "yellow-100": #FFDFB0,
  "yellow-200": #FFCB7C,
  "yellow-300": #FFB547,
  "yellow-400": #FFA51A,
  "yellow-500": #FF9600,
  "yellow-600": #FB8A00,
  "yellow-700": #F57900,
  "yellow-800": #EF6900,
  "yellow-900": #E74D00,
);

@mixin generate-color-classes($colors) {
  @each $color-name, $color in $colors {
    .bg-theme-#{$color-name} {
      background-color: $color !important;
    }

    .color-theme-#{$color-name} {
      color: $color !important;
    }

    .border-color-theme-#{$color-name} {
      border-color: $color !important;
    }
  }
}

@include generate-color-classes($colors);
